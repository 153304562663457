/* --------------------------------
   partners
-------------------------------- */

.partners-list
{
	line-height: 0;
	font-size: 0;
	letter-spacing: -1px;
	@extend %text-center;
	overflow: hidden;

	.partners-list__inner
	{
		margin-left: -15px;
		margin-right: -15px;
		margin-bottom: -30px;
	}

	.partners-list__item
	{
		@extend %display-inline-block;
		@extend %v-middle;
		line-height: 1;
		font-size: 15px;
		letter-spacing: 0;
		margin-left: 15px;
		margin-right: 15px;
		margin-bottom: 30px;
	}
}