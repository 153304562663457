/* --------------------------------
   faq
-------------------------------- */

.faq-list
{
	.faq-list__inner { margin-bottom: -50px; }

	.faq-item
	{
		position: relative;
		margin-bottom: 50px;
	}
}