/* --------------------------------
   team
-------------------------------- */

.team
{
	& &__inner { margin-bottom: -30px; }

	& &__item
	{
		@extend %pos-relative;
		overflow: hidden;
		background-color: $primary-color;
		max-width: 400px;
		padding-bottom: 110px;
		margin-bottom: 30px;

		.social-btns { margin-top: 50px; }

		&:hover
		{
			.team__photo
			{
				opacity: 0.75;
				transform: translateY(-30px);
			}

			.team__description
			{
				background-color: $primary-color;
				padding-top: 45px;
				padding-bottom: 45px;
				transform: translateY(-100%);
			}

			.team__name,
			.team__position
			{
				color: $white;
			}
		}
	}

	& &__photo
	{
		@extend %pos-relative;
		@extend %height-0;
		padding-top: percentage(500/370);
		@include transition(
			opacity 0.3s ease-in-out,
			transform 0.3s ease-in-out
		);

		img { @extend %block-absolute--full; }
	}

	& &__description
	{
		@extend  %pos-absolute;
		top: 100%;
		left: 0;
		@extend %width-100;
		padding: 35px 15px;
		@extend %text-center;
		background-color: $primary-color-light;
		transform: translateY(-110px);
		@include transition(
			background-color 0.3s ease-in-out,
			padding 0.3s ease-in-out,
			transform 0.3s ease-in-out
		);
	}

	& &__name { margin-bottom: 5px; }

	& &__position
	{
		margin-top: 5px;
		color: #aaaaaa;
	}
}

@include min-screen(381px)
{
	.team
	{
		& &__item
		{
			&:hover
			{
				.team__description
				{
					padding-top: 60px;
					padding-bottom: 60px;
				}
			}
		}
	}
}