/* --------------------------------
   apps
-------------------------------- */

.section-apps
{
	background-color: #010101;
	color: $white;
}

@include min-screen($sm-width)
{
	.section-apps {}
}

@include min-screen($md-width)
{
	.section-apps
	{
		margin-bottom: 100px;

		.img-place
		{
			position: absolute;
			top: -20px;
			right: 15px;
		}

		.text
		{
			padding-top: 60px;
		}
	}
}

@include min-screen($lg-width)
{
	.section-apps
	{
		.img-place
		{
			left: 0;
			right: auto;
		}

		.text
		{
			padding-top: 110px;
			padding-bottom: 35px;
		}
	}
}