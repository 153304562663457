/* --------------------------------
   footer
-------------------------------- */

#footer
{
	@extend %pos-relative;
	background-color: $secondary-color;

	.s-form
	{
		@extend %pos-relative;
		background-color: $primary-color;
	}

	.footer__form
	{
		@extend %width-100;

		.input-wrp
		{
			input,
			textarea
			{
				border-bottom-color: rgba($white,0.37);
				color: #c5def7;

				@include placeholder { color: #c5def7; }
			}

			i { }
		}

		button[type=submit]
		{
			margin-top: 25px;
			padding-top: 13px;
			padding-bottom: 13px;
		}
	}

	.contact__item
	{
		@extend %pos-relative;
		line-height: 1.4;
		font: {
			size: rem-calc(16px);
			weight: 300;
		};
		color: $white;
		padding-left: 55px;
		margin-bottom: 60px;

		>a
		{
			color: inherit;

			&[href^="mailto"]
			{
				padding: 2px 0;
				border-bottom: 1px solid rgba($white,0.5);

				&:hover,
				&:focus
				{
					border-bottom-color: transparent;
				}
			}
		}

		i
		{
			@extend %fl-l;
			margin-left: -55px;
			line-height: 1;
			font-size: rem-calc(30px);
			color: rgba($white,0.5);
		}
	}

	.footer__copy
	{
		line-height: 1.9;
		color: #6d6e71;
		font-size: rem-calc(12px);

		a
		{
			text-decoration: underline;

			&:hover { text-decoration: none; }
		}
	}

	.social-btns
	{
		a {}
	}

	&.footer--style-1
	{
		.footer__form
		{
			max-width: 630px;

			.input-wrp
			{
				textarea { margin-top: 55px; }
			}
		}

		.footer__contact { padding-top: 85px; }

		.footer__bottom-text
		{
			border-top: 1px solid #363a3c;
			padding: 30px 0;
		}

		.social-btns
		{
			text-align: center;
			margin-bottom: 30px;
		}

		.footer__copy { @extend %fl-l; }
	}

	&.footer--style-2
	{
		.s-form:before
		{
			content: "";
			@extend %pos-absolute;
			top: 0;
			bottom: 0;
			left: -15px;
			right: -15px;
			background-color: $primary-color;
		}
	}

	&.footer--style-3
	{
		.footer__form { max-width: 700px; }

		.map-container
		{
			position: relative;
			height: 350px;
			width: 100%;
			z-index: 2;

			.g_map { height: 100%;}
		}
	}

	&.footer--style-2 .footer__contact,
	&.footer--style-3 .footer__contact,
	.s-form
	{
		padding-top: 60px;
		padding-bottom: 60px;
	}
}

@include min-screen(481px)
{
	#footer
	{
		&.footer--style-1
		{
			.social-btns
			{
				float: right;
				margin-bottom: 0;
			}
		}

		&.footer--style-3
		{
			.footer__form
			{
				.b-table
				{
					.cell
					{
						padding-left: 20px;
						width: 25%;
						min-width: 170px;

						&:first-child
						{
							padding-left: 0;
							width: auto;
							min-width: 0;
						}
					}
				}

				.input-wrp { margin-bottom: 0; }

				button[type=submit] { margin-top: 0; }
			}

			.map-container { height: 500px; }
		}
	}
}


@include min-screen($sm-width)
{
	#footer
	{
		&.footer--style-2 .footer__contact,
		&.footer--style-3 .footer__contact,
		.s-form
		{
			padding-top: 80px;
			padding-bottom: 80px;
		}

		&.footer--style-2
		{
			&:before
			{
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: 50%;
				background-color: $primary-color;
			}
		}

		&.footer--style-3
		{
			.map-container
			{
				float: right;
				width: 50%;
				height: 700px;
			}
		}
	}
}

@include min-screen($md-width)
{
	#footer
	{
		&.footer--style-2 .footer__contact,
		&.footer--style-3 .footer__contact,
		.s-form
		{
			padding-bottom: 100px;
		}

		&.footer--style-2 .footer__contact,
		&.footer--style-3 .footer__contact
		{
			padding-top: 155px;
		}

		.s-form
		{
			padding-top: 100px;
		}
	}
}

@include max-screen(480px)
{
	#footer
	{
		&.footer--style-3
		{
			.footer__form
			{
				.b-table
				{
					display: block;

					.cell { display: block; }
				}
			}
		}
	}
}