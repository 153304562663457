/* main colors */
$white               : #fff !default;
$primary-color       : #3c83cb !default;
$secondary-color     : #252a2d !default;
$primary-color-light : lighten(desaturate(adjust-hue($primary-color, 4.0759), 16.7183), 45.0980);

$info-color    : $primary-color !default; /*blue*/
$danger-color  : #ff4241 !default; /*red*/
$success-color : #a7d212 !default; /*green*/
$warning-color : #edc214 !default; /*yellow*/

/* main fonts */
$base-fontFamily-1 : 'Roboto', sans-serif !default;
$base-fontFamily-2 : 'Raleway', sans-serif !default;

$base-font-size: 10px !default;

/* main breakpoint */
$lg-width: 1200px !default;
$md-width: 992px !default
$sm-width: 768px !default;