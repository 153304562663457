a
{
	color: $primary-color;
	outline: none;
	@include transition(color 0.3s ease-in-out);

	&:hover,
	&:focus
	{
		color: $primary-color;
		text-decoration: none;
	}
}

.h1,h1,
.h2,h2,
.h3,h3,
.h4,h4,
.h5,h5
{
	line-height: 1.1;
	font: {
		weight: 700;
		family: $base-fontFamily-2;
	};
	@include transition(color 0.3s ease-in-out);

	&:first-child { margin-top: 0; }
	&:last-child  { margin-bottom: 0; }

	main & { color: #444; }

	a { color: inherit; }
}

.h1,h1 { font-size: rem-calc(40px); }
.h2,h2 { font-size: rem-calc(25px); }
.h3,h3 { font-size: rem-calc(18px); }
.h4,h4 { font-size: rem-calc(16px); }
.h5,h5 { font-size: rem-calc(14px); }

.h1,h1
{
	margin-top: 25px;
	margin-bottom: 25px;

	&.h2,
	&.h3
	{
		margin-top: 20px;
		margin-bottom: 20px;
	}

	&.h4,
	&.h5
	{
		margin-top: 15px;
		margin-bottom: 15px;
	}
}

.h2,h2,
.h3,h3
{
	margin-top: 20px;
	margin-bottom: 20px;

	&.h4,
	&.h5
	{
		margin-top: 15px;
		margin-bottom: 15px;
	}
}

.h4,h4,
.h5,h5
{
	margin-top: 15px;
	margin-bottom: 15px;

	&.h2,
	&.h3
	{
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

.h5,h5
{
	line-height: 1.2;
	font-weight: 400;
}

.section-heading
{
	@extend %pos-relative;
	line-height: 1.4;
	font: {
		size: rem-calc(16px);
		family: $base-fontFamily-2;
		weight: 400;
	};
	color: #a7a7a7;
	@extend %width-100;
	max-width: 600px;
	padding-top: 95px;
	margin-bottom: 65px;
	z-index: auto;

	&:first-child
	{
		padding-top: 0;
		margin-top: -5px;
	}

	&:last-child { margin-bottom: 0; }

	&.section-heading--white
	{
		color: $white;

		.title { color: inherit; }
	}

	&.section-heading--left
	{
		@extend %text-left;
		margin-left: 0;
		margin-right: auto;
	}

	&.section-heading--center
	{
		@extend %text-center;
		margin-left: auto;
		margin-right: auto;
	}

	&.section-heading--right
	{
		@extend %text-right;
		margin-left: auto;
		margin-right: 0;
	}

	.b-video & { padding-top: 43px; }

	.title { color: #333; }

	p { margin-top: 25px; }
}

main
{
	p
	{
		margin-top: 20px;
		margin-bottom: 20px;

		&:first-child { margin-top: 0; }
		&:last-child  { margin-bottom: 0; }
	}
}